<!-- SelectGame.vue -->
<template>
    <div class="selectgame">
        <div>
            <input type="text" v-model="searchTerm" placeholder="Search games" />
            <div class="select">
                <div v-for="item in filteredItems" :key="item.id" :class="{ selected: selectedItems.includes(item) }" @click="selectGame(item.id)" class="game">
                    <div>
                        <div class="players">
                            <div class="player" v-for="p in item.participations" :key="p.id">
                                    <div class="info">
                                        <p class="name">{{ p.player.user.name }}</p>
                                        <p class="avarage">{{ formatFloat(p.average) }}</p>
                                    </div>
                                    <div class="avatar">
                                    <div>
                                        <img v-if="p.player.avatar" :src="'avatars/' + p.player.avatar"  />
                                        <img class="square" src="https://via.placeholder.com/246" alt="">
                                    </div>
                                    <p>${ p.sets_won }</p>
                                </div>
                            </div>
                        </div>
                        <div class="info">
                            <p class="name">{{ item.description }}</p>
                            <p class="date">{{ item.updated_at }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="submit" v-if="!this.direct || this.multiple !== undefined">
            <span @click="submitSelection" class="button">
                {{ buttonlabel || "Select game" }}
            </span>
        </div>
    </div>
</template>

<script>
import { ref, computed, toRefs } from "vue";

export default {
    props: ["items", "multiple", "buttonlabel", "direct"],
    setup(props, { emit }) {
        const { items } = toRefs(props);
        const selectedItems = ref([]);
        const searchTerm = ref("");

        const emitSelectedItems = () => {
            if (props.multiple) {
                emit("update:selecteditems", selectedItems.value);
            } else {
                emit("update:selecteditems", selectedItems.value[0]);
            }
        };
        const filteredItems = computed(() => {
            if(!items.value) return;
            var itemsSortedByNothing = items.value.sort(function (a, b) {
                return 1
            });
            if (!searchTerm.value) return itemsSortedByNothing;
            return itemsSortedByNothing.filter((item) =>
                item.name
                    .toLowerCase()
                    .includes(searchTerm.value.toLowerCase())
            );
        });
        const submitSelection = () => {
            emitSelectedItems();
        };
        return {
            selectedItems,
            submitSelection,
            searchTerm,
            emitSelectedItems,
            filteredItems,
        };
    },
    methods: {
        formatFloat: function (float) {
            return float ? float.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'n/a';
        },
        selectGame(id) {
            if (!this.multiple) {
                this.selectedItems = [];
            }
            if (this.selectedItems.includes(id)) {
                this.selectedItems = this.selectedItems.filter(
                    (item) => item.id !== id
                );
            } else {
                this.selectedItems.push(
                    this.items.find((item) => item.id === id)
                );
            }
            if (this.direct !== undefined) {
                this.emitSelectedItems();
                this.selectedItems = [];
            }
        },
    },
};
</script>
