<!-- SelectPlayers.vue -->
<template>
    <div class="selectplayers">
        <div>
            <input
                type="text"
                v-model="searchTerm"
                placeholder="Search players by name"
            />
            <div class="select">
                <div
                    v-for="item in filteredItems"
                    :key="item.id"
                    :class="{ selected: selectedItems.includes(item) }"
                    @click="selectPlayer(item.id)"
                >
                    <span class="name">{{ item.user.name }}</span>
                    <span class="stats">
                        <span class="avg" v-if="item.average"
                            ><img src="/images/Average.svg"><span>{{ average(item.average) }}</span></span
                        >
                        <span class="avg na" v-if="!item.average"
                            ><img src="/images/Average.svg"><span>n/a</span></span
                        >
                        <span class="dq" v-if="item.doublequota"
                            >DQ&nbsp;{{ doubleQuota(item.doublequota) }}%</span
                        >
                        <span class="dq na" v-if="!item.doublequota">DQ&nbsp;n/a</span>
                    </span>
                </div>
            </div>
        </div>
        <div class="submit" v-if="!this.direct || this.multiple !== undefined">
            <span @click="submitSelection" class="button">
                {{ buttonlabel || "Alright, mate." }}
            </span>
        </div>
    </div>
</template>

<script>
import { ref, computed, toRefs } from "vue";

export default {
    props: ["items", "multiple", "buttonlabel", "direct"],
    setup(props, { emit }) {
        const { items } = toRefs(props);
        const selectedItems = ref([]);
        const searchTerm = ref("");

        const emitSelectedItems = () => {
            if (props.multiple) {
                emit("update:selecteditems", selectedItems.value);
            } else {
                emit("update:selecteditems", selectedItems.value[0]);
            }
        };

        const filteredItems = computed(() => {
            var itemsSortedByDoubleQuota = items.value.sort(function (a, b) {
                return b.doublequota - a.doublequota;
            });
            if (!searchTerm.value) return itemsSortedByDoubleQuota;
            return itemsSortedByDoubleQuota.filter((item) =>
                item.user.name
                    .toLowerCase()
                    .includes(searchTerm.value.toLowerCase())
            );
        });

        const submitSelection = () => {
            emitSelectedItems();
        };

        return {
            selectedItems,
            submitSelection,
            searchTerm,
            emitSelectedItems,
            filteredItems,
        };
    },
    methods: {
        doubleQuota: function (float) {
            return (float * 100).toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2})
        },
        average: function (float) {
            return float.toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },
        selectPlayer(id) {
            if (!this.multiple) {
                this.selectedItems = [];
            }
            if (this.selectedItems.includes(id)) {
                this.selectedItems = this.selectedItems.filter(
                    (item) => item.id !== id
                );
            } else {
                this.selectedItems.push(
                    this.items.find((item) => item.id === id)
                );
            }
            if (this.direct !== undefined) {
                this.emitSelectedItems();
                this.selectedItems = [];
            }
        },
    },
};
</script>
