const {
    default: axios
} = require("axios");
import {
    ref, createApp
} from 'vue';
import Login from '../components/login';
import SelectPlayers from "../components/player-select";
import SelectGame from "../components/game-select";

window.addEventListener('load', function () {
 
    const app = createApp({
        delimiters: ['${', '}'], // ff
        components: {
            SelectPlayers,
            SelectGame,
        },
        data() {
            return {
                waitforapi: false,
                // Debugging
                debugStack: [],
                cntUpdateGame: 0,
                cntReceivedUpdateGame: 0,
                cntPushUpdateWebcam: 0,
                cntReceiveUpdateWebcam: 0,
                cntPeer: 0,
                    justMyOwnGames: "1",
                cntRecPeer: 0,
                cntCallPeer: 0,
                cntPusher: 0,
                // Dashboard
                // UI
                disableCam: true,
                page: 'dashboard',
                pusher: null,
                pusherChannel: null,
                godMode: false,
                tickerTimer: null,
                displayScore: [],
                displayScoreCntDown: [],
                scoreInput: '',
                digits: [7,8,9,4,5,6,1,2,3,0],
                selectedPlayers: [],
                peer: null,
                peerGuests: [],
                badge: '',
                // Games
                games: [],
                firstLoad: true,
                secret: '',
                opponentJoinedGame: [],
                guestCamInterval: null,
                showWebcamSelect: false,
                webcamPusher: null,
                webcamTries: 0,
                webcamList: [],
                audioList: [],
                webcamToUse: null,
                audioToUse: null,
                stream: false,
                call: null,
                game: false,
                gameError: '',
                multiplier: 1,
                dart: 1,
                round: 1,
                leg: 1,
                set: 1,
                cntDarts: false,
                cntDoubleDarts: false,
                gameStates: [{
                        'pending': 'Pending'
                    },
                    {
                        'on': 'Game on'
                    },
                    {
                        'finished': 'Finished'
                    },
                    {
                        'aborted': 'Aborted'
                    }
                ],
                newGame: {
                    name: '',
                    description: '',
                    startscore: 501,
                    state: 'pending',
                    sets_cnt: 1,
                    legs_cnt: 3,
                    round: 1,
                    inputmode: 1
                },
                // User
                players: [],
                user: {
                    id: 0,
                    player_id: 0,
                    name: '',
                    firstname: '',
                    lastname: '',
                    colour: '',
                    country: '',
                    language: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    remember_me: false,
                    token: '',
                    error: '',
                    games: []
                },
                // League
                league: [],
                leagueRound: {
                    game: 0,
                    round: 0,
                },
                newLeague: {
                    name: '',
                    description: '',
                    state: 'pending',
                    sets_cnt: 1,
                    legs_cnt: 3,
                    round: 1,
                    inputmode: 1
                }
            }
        },
        methods: {
            // UI
            setWaitforapi: function (waitforapi) {
                this.waitforapi = waitforapi;
            },
            setPage: function (page) {
                if(page!="game" && this.game) {
                    window.clearInterval(this.webcamPusher);
                    if(this.game.id && this.getOpponent) {
                        this.leaveGame();
                        this.pusherChannel.unbind('updategame'+this.game.id+"_"+this.getOpponent);
                        this.pusherChannel.unbind('updatewebcam'+this.game.id+"_"+this.getOpponent);
                    }
                    this.webcamPusher = null;
                    this.stream = false;
                    this.game = false;
                    this.secret = '';
                    this.firstLoad = true;
                    this.opponentJoinedGame = [];
                    this.guestCamInterval = null;
                    this.scoreInput = '';
                    this.webcamTries = 0;
                } else if(page=="game") {
                    window.setTimeout(function() {
                        this.initWebcam();
                    }.bind(this), 1024);
                }
                this.page = page;
            },
            setPageNewLeague() {
                this.league = { name: '', rounds: [] };
                this.selectedPlayers = [];
                this.newLeague = { 
                    name: '',
                    legs_cnt: 3,
                    sets_cnt: 3,
                    startscore: 501,
                    inputmode: 1
                }
                this.setPage('newleague');
            },
            setLocale: function (locale) {
                this.locale = locale;
                axios.post("/locale", {
                        locale: locale
                    })
                    .then((response) => {
                        document.location.reload();
                    })
                    .catch((error) => {
                        // NOOP
                    });
            },
            initPusher: function() {
                this.pusher = new Pusher("ab1d1b3cf862478405ff", {
                    cluster: "eu",
                });
                this.pusherChannel = this.pusher.subscribe("dartmate");  
            },
            getWebcams: function() {
                var webcams = [];
                navigator.mediaDevices.enumerateDevices()
                    .then(function(devices) {
                        console.log(devices);
                        this.webcamList = devices.filter(device => device.kind === 'videoinput');
                        this.audioList = devices.filter(device => device.kind === 'audioinput');
                        this.webcamToUse = this.webcamList[0];
                        this.audioToUse = this.audioList[0];
                    }.bind(this))
                    .catch(function(err) {
                        this.webcamList = [];
                    }.bind(this));
            },
            initWebcam: function() {
                if(this.disableCam || this.hasFinished || this.peer || this.justWatching ) {
                    return;
                };
                this.getWebcams();
                this.showWebcamSelect = false;
                this.peer = new Peer();
                this.cntPeer++;
                this.game.participations.forEach(participant => {
                    if(participant.player_id != this.user.player_id) {
                        console.log("%cBinding to updatewebcam"+this.game.id+"_"+participant.player_id,"padding: 0.3em; color: white; background: #58a94e");
                        this.pusherChannel.bind('updatewebcam'+this.game.id+"_"+participant.player_id, function( id ) {
                            console.log("%cReceived webcam/peer id","padding: 0.3em; color: #58a94e; background: #d5ddd5");
                            console.log(id);
                            if(id.message == this.peer.id) {
                                console.log("Na, this is me.");
                                return;
                            }
                            this.receiveUpdateWebcam(id);
                        }.bind(this));
                            this.cntPushUpdateWebcam++;
                    }
                });
                // Establishing some events
                this.peer.on('open', function(id) {
                    console.log("%cPeer open","color: #d8b0db");
                    var tries = 0;
                    this.setWebcam(this.webcamToUse, "webcam"+this.user.player_id);
                    this.setAudio(this.audioToUse, "audio"+this.user.player_id);
                    this.peerGuests[this.user.player_id] = this.peer.id;
                    this.waitForCams();
                }.bind(this));
                // Event when peer has error
                this.peer.on('error', function(err) {
                    console.error("Peer JS Error:",err);
                }.bind(this));
                // Event when peer is called    
                this.peer.on('call', function( call ) {
                    this.cntRecPeer++;
                    console.log("%cGot a call from "+call.peer,"padding: 0.3em; color: white; background: #ff0000");
                    console.log(this.peerGuests);
                    call.answer(this.justWatching?null:this.stream);
                    call.on('stream', function(remoteStream) {
                        this.peerGuests.forEach( function(peerGuest, player_id) {
                            if( peerGuest == call.peer) {
                                var webcam = document.querySelector("#webcam"+player_id);
                                if(webcam && webcam.srcObject != remoteStream) {
                                    webcam.srcObject = remoteStream;
                                }
                            }
                        });
                    }.bind(this));
                }.bind(this));
            },
            setWebcam: function( cam, camContainer ) {
                this.showWebcamSelect = false;
                if( cam ) {
                    console.log("New Webcam",cam);
                    navigator.mediaDevices.getUserMedia( { video: { deviceId: cam.deviceId } } )
                    .then(function (stream) {
                        if(stream) {
                            this.stream = stream;
                            var webcam = document.querySelector("#"+camContainer);
                            if(webcam) {
                                console.log("New stream for my own cam.",stream);
                                webcam.srcObject = stream;
                                this.stream = stream;
                                this.webcamToUse = cam;
                                this.pushUpdateWebcam();
                            }
                        }
                    }.bind(this));
                }
            },
            setAudio: function( audio, audioContainer ) {
                this.showAudioSelect = false;
                if( audio ) {
                    console.log("New Audio",audio);
                    navigator.mediaDevices.getUserMedia( { audio: { deviceId: audio.deviceId } } )
                    .then(function (stream) {
                        if(stream) {
                            this.stream = stream;
                            var audioelem = document.querySelector("#"+audioContainer);
                            if(audioelem) {
                                console.log("New stream for my own audio.",stream);
                                audioelem.srcObject = stream;
                                this.audiostream = stream;
                                this.audioToUse = audio;
                            }
                        }
                    }.bind(this));
                }
            },
            waitForCams: function() {
                if(this.justWatching) {
                    return;
                }
                var allCams = true;
                document.querySelectorAll(".webcam").forEach( webcam => {
                    if(!webcam.srcObject) {
                        allCams = false;
                    }
                });
                if(allCams) {
                    return;
                }
                this.pushUpdateWebcam();
                if(this.webCamtries++ > 10) {
                    console.error("No webcam found.");
                    return;
                }
                window.setTimeout(function() {
                    this.waitForCams();
                }
                .bind(this), 6000);
            },
            pushUpdateWebcam: function () {
                if(!this.peer?.id) {
                    console.log("No peer id");
                    return;
                }
                this.game.participations.forEach(participant => {
                    if(this.call && this.peerGuests[participant.player_id] && this.peerGuests[participant.player_id]!=this.peer.id) {
                        console.log("Calling "+this.peerGuests[participant.player_id]+" with "+this.stream);
                        this.call = this.peer.call(this.peerGuests[participant.player_id], this.stream);
                    }
                });
                axios.post("/updatewebcam", {
                        peerId: this.peer.id,
                        stream: this.stream,
                        eventId: this.game.id+"_"+this.user.player_id,
                        player_id: this.user.player_id
                    }).then((response) => {
                    }).catch((error) => {
                        this.user.error = error.response.data.message;
                    }
                );
            },
            receiveUpdateWebcam: function( payload ) {
                var cam = false;
                this.cntReceiveUpdateWebcam++;
                if(!payload.peerId || payload.peerId == this.peer.id) {
                    return;
                };
                this.peerGuests[ payload.player_id ] = payload.peerId;
                console.log('Calling '+this.peerGuests[ payload.player_id ]+' with '+this.stream);
                this.call = this.peer.call(this.peerGuests[ payload.player_id ], this.stream?this.stream:1);
                this.cntCallPeer++;
                if(!this.call) {
                    console.log("Call failed.");
                    return;
                }
                console.log("Good call",payload);
            },
            toggleDisableCam: function () {
                this.disableCam = !this.disableCam;
            },
            toggleGodMode: function () {
                this.godMode = !this.godMode;
            },
            ticker: function () {
                if(!this.game) {
                    return;
                }
                this.game.participations.forEach(p => {
                    if(this.displayScore[p.id] == p.score) {
                        return;
                    }
                    if(p.score < this.displayScore[p.id]) {
                        this.displayScore[p.id] = Math.max(0, Math.floor(this.displayScore[p.id] - this.displayScoreCntDown[p.id]));
                        console.log("Tick down"+this.displayScoreCntDown[p.id]);
                    } else if(p.score > this.displayScore[p.id]) {
                        this.displayScore[p.id] = Math.min(p.score,Math.max(0, Math.floor(this.displayScore[p.id] + this.displayScoreCntDown[p.id])));
                        console.log("Tick up"+this.displayScoreCntDown[p.id]);
                    }
                });
            },
            speak: function (text) {
                //var msg = new SpeechSynthesisUtterance(text);
                //window.speechSynthesis.speak(msg);
            },
            formatFloat: function (float) {
                return float?float.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}):'n/a';
            },
            doubleQuota: function (float) {
                return (float*100).toFixed(1);
            },
            getScores: function (participant,set,leg,force) {
                if(!force && this.getLegWinner(set,leg)) {
                    return false;
                }
                set = set || 0;
                leg = leg || 0;
                var scores = [];
                participant.orddarts.forEach(d => {
                    if((d.set == set || set == 0) && (d.leg == leg || leg == 0)) {
                        if( this.game.inputmode == 1) {
                            scores.push({ score: d.score, darts: [] });
                        } else {
                            if(scores[d.round-1] == undefined) {
                                scores[d.round-1] = { score: 0, darts: [] };
                            }
                            scores[d.round-1].score += d.score; // multiplier == 0 means score, not single darts
                            scores[d.round-1].leg = d.leg;
                            scores[d.round-1].darts.push(d);
                        }
                    }
                });
                if(scores.length) {
                    for(var i=0;i<scores.length%6;i++) {
                        scores.push({ score: -1, darts: [] });
                    }
                }
                return scores.length?scores:false;
            },
            // Games
            wonBullout: function (id) {
                if(this.game.currentplayer || (!this.userIsParticipant && !this.godMode) ) {
                    return;
                }
                this.game.currentplayer = id;
                this.game.leg_starter = id;
                this.game.set_starter = id;
                this.game.dart = 1;
                this.multiplier = 1;
                this.storeGame(this.game);
            },
            setMultiplier: function (multiplier) {
                this.multiplier = multiplier;
            },
            setCntDarts: function (cntDarts) {
                this.cntDarts = cntDarts;
            },
            setCntDoubles: function (cntDoubleDarts) {
                this.cntDoubleDarts = cntDoubleDarts;
            },
            addDart: function (dart) {
                if (this.game.status == 'on') {
                    if(this.multiplier!=2 && this.firstDart) {
                        this.gameError = "First dart must be a double";
                        return;
                    }
                    this.gameError = "";
                    if(dart >= 25) {
                        this.multiplier = 1;
                    }
                    for(var i = 0; i < this.game.participations.length; i++) {
                        var participant = this.game.participations[i];
                        if (participant.player_id == this.game.currentplayer) {
                            if(dart<50 && (this.multiplier!=2 && participant.score-this.multiplier * dart == 0)) {
                                this.gameError = "Bust! You need to finish with a double or bullseye.";
                                this.speak(this.gameError);
                                this.nextPlayer('bust');
                                return;
                            }
                            if(participant.score-this.multiplier * dart < 0) {
                                this.gameError = "Bust! Score can't be negative.";
                                this.speak(this.gameError);
                                this.nextPlayer('bust_negative');
                                return;
                            } else if(participant.score-this.multiplier * dart == 1) {
                                this.gameError = "Bust! Score can't be 1.";
                                this.speak(this.gameError);
                                this.nextPlayer('bust_1');
                                return;
                            }
                            participant.score -= this.multiplier * dart;
                            participant.orddarts.push({
                                multiplier: this.multiplier,
                                value: dart,
                                score: this.multiplier * dart,
                                round: this.getRoundOfGame(),
                                leg: this.game.leg,
                                set: this.game.set,
                                dart: this.game.dart,
                                darts: this.cntDarts,
                                doubledarts: this.cntDoubleDarts,
                            });
                            if(this.multiplier == 2) {
                                this.speak("Double " + dart);
                            } else if(this.multiplier == 3) {
                                this.speak("Triple " + dart);
                            } else {
                                this.speak(dart);
                            }
                            if(participant.score == 0) {
                                this.legWon(participant);
                                return;
                            }
                            this.storeParticipant(participant);
                        }
                    };
                    this.multiplier = 1;
                    this.game.dart++;
                    if (this.game.dart > 3) {
                        this.nextPlayer('next_player');
                    }
                    this.storeGame(this.game);
                }
            },
            addScore: function () {
                this.gameError = "";
                if (this.game.status == 'on') {
                    for(var i = 0; i < this.game.participations.length; i++) {
                        var participant = this.game.participations[i];
                        if (participant.player_id == this.game.currentplayer) {
                            if(participant.score-this.scoreInput < 0) {
                                this.gameError = "Bust! Score can't be negative.";
                                this.speak(this.gameError);
                                this.scoreInput = 0;
                            } else if(participant.score-this.scoreInput == 1) {
                                this.gameError = "Bust! Score can't be 1.";
                                this.speak(this.gameError);
                                this.scoreInput = 0;
                            } else if(participant.score <= 170 && this.cntDarts === false) {
                                this.gameError = "Please enter the number of darts used.";
                                this.speak(this.gameError);
                                return;
                            } else if(participant.score <= 170 &&  this.cntDoubleDarts === false) {
                                this.gameError = "Please enter the number of doubles hit.";
                                this.speak(this.gameError);
                                return;
                            }
                            participant.orddarts.push({
                                multiplier: 0,
                                value: 0,
                                score: this.scoreInput,
                                round: this.getRoundOfGame(),
                                leg: this.game.leg,
                                set: this.game.set,
                                dart: 0,
                                darts: this.currentPlayerCanCheckOut?this.cntDarts:3,
                                doubledarts: this.currentPlayerCanCheckOut?this.cntDoubleDarts:0,
                            });
                            this.displayScoreCntDown[participant.id] = Math.max(1,Math.floor((this.scoreInput)/100));
                            participant.score -= this.scoreInput;
                            this.scoreInput = '';
                            this.cntDoubleDarts = false;
                            this.cntDarts = false;
                            this.speak(this.scoreInput);
                            if(participant.score == 0) {
                                this.legWon(participant);
                                return;
                            }
                            this.storeParticipant(participant);
                        }
                    };
                }
                this.nextPlayer('add_score');
            },
            totalScore: function (participant) {
                var total = 0;
                participant.orddarts.forEach(orddart => {
                    total += orddart.score;
                });
                return total;
            },
            totalDarts: function (participant) {
                var darts = 0;
                participant.orddarts.forEach(orddart => {
                    darts += 3;
                });
                return darts;
            },
            showScores: function ( ev ) {
                var el = ev.target;
                console.log(ev,el);
                el.parentNode.querySelector(".darts").classList.toggle("forceShow");
            },
            addDigit: function (digit) {
                this.scoreInput = this.scoreInput * 10 + digit;
            },                    
            deleteDigit: function () {
                this.scoreInput = (Math.floor(this.scoreInput / 10)>0)?Math.floor(this.scoreInput / 10):'';
            },
            getRoundOfGame: function (participation) {
                if(participation===undefined) {
                    participation = this.game.participations.find(participant => participant.player_id == this.game.currentplayer);
                }
                var round = 0;
                if(participation?.orddarts) {
                    participation.orddarts.forEach(orddart => {
                        if (orddart.set == this.game.set && orddart.leg == this.game.leg && orddart.round > round && (orddart.dart == 3 || orddart.dart == 0)) { // dart == 0 means score, not single darts
                            round = orddart.round;
                        }
                    });
                }
                return round+1;
            },
            legWon: function (participant) {
                // Participant won this leg
                var setidx = this.game.set-1;
                var legidx = this.game.leg-1;
                this.game.sets[setidx].legs[legidx].game_id = this.game.id;
                this.game.sets[setidx].legs[legidx].player_id = participant.player_id;
                this.game.sets[setidx].legs[legidx].leg = this.game.leg;
                this.game.sets[setidx].legs[legidx].set_id = this.game.sets[this.game.set-1].id;
                // Also won this set?
                var legsToWin = Math.ceil(this.game.legs_cnt/2);
                var someoneWon = false;
                this.game.participations.forEach(participant => {
                    this.displayScoreCntDown[participant.id] = Math.max(1,Math.floor((this.game.startscore-participant.score)/100));
                    participant.score = this.game.startscore;
                    participant.legstmp = 0;
                    var wonLeg = false;
                    this.game.sets[setidx].legs.forEach(leg => {
                        if (leg.player_id == participant.player_id) {
                            participant.legstmp++;
                            if(participant.legstmp == legsToWin) {
                                someoneWon = true;
                                this.setWon(participant);
                                this.game.leg = 1;
                                wonLeg = true;
                            }
                        }
                    });
                    if(!wonLeg) {
                        this.storeParticipant(participant);
                    }
                    
                });
                if(!someoneWon) {
                    this.game.leg++;
                    console.log('Calling next player legwon')
                    this.nextPlayer('legwon');
                    if (this.game.leg > this.game.legs_cnt) {
                        this.game.leg = 1;
                        alert("Something wen't utterly wrong. You shouldn't be here. Why didn't someone win the set? Please look into the console.");
                    }                        
                }
            },
            setWon: function (participant) {
                // Participant won this set
                this.game.sets[this.game.set-1].game_id = this.game.id;
                this.game.sets[this.game.set-1].player_id = participant.player_id;
                this.game.sets[this.game.set-1].set = this.game.set;
                var setsToWin = Math.ceil(this.game.sets_cnt/2);
                var someoneWon = false;
                this.game.participations.forEach(participant => {
                    participant.sets = 0;
                    this.game.sets.forEach(set => {
                        if (set.player_id == participant.player_id) {
                            participant.sets++;
                            if(participant.sets == setsToWin) {
                                this.gameWon(participant);
                                someoneWon = true;
                            }
                        }
                    });
                });
                if(!someoneWon) {                    
                    this.storeParticipant(participant);
                    this.nextPlayer('setwon');
                    this.game.set++;
                    this.game.leg = 1;
                    this.storeGame(this.game);
                }
            },
            gameWon: function (participant) {
                this.game.winner = participant.player_id;
                this.game.winnerName = participant.user.name;
                this.game.status = 'finished';
                this.storeParticipant(participant);
                this.storeGame(this.game);
            },
            getWonLegs: function (participant) {
                var legs = 0;
                this.game.sets[this.game.set-1].legs.forEach(leg => {
                    if (leg.player_id == participant.player_id) {
                        legs++;
                    }
                });
                return legs;
            },
            getLegWinner: function( set, leg ) {
                var winner = this.game?.sets[set-1]?.legs[leg-1].player_id?this.game?.sets[set-1]?.legs[leg-1].player_id:false;
                return winner;
            },
            getWonSets: function (participant) {
                var sets = 0;
                this.game.sets.forEach(set => {
                    if (set.player_id == participant.player_id) {
                        sets++;
                    }
                });
                return sets;
            },
            totalScore: function (participant) {
                var total = 0;
                participant.orddarts.forEach(orddart => {
                    total += orddart.score;
                });
                return total;
            },
            nextPlayer: function ( mode = 'next' ) {
                if (this.game.status == 'on') {
                    var next = false;
                    var done = false;
                    var lookfor = this.game.currentplayer;
                    switch(mode) {
                        case 'legwon':
                        case 'setwon': // TODO: Will implement set switching later
                            console.log("%cLooking for leg starter.","padding: 0.3em; color: white; background: #ff0000");
                            lookfor = this.game.leg_starter;
                            break;
                        default:
                            console.log("Looking for next player.");
                    }
                    console.log("Looking for "+lookfor);
                    this.game.dart = 1;
                    this.multiplier = 1;
                    this.scoreInput = '';
                    this.cntDarts = false;
                    this.cntDoubleDarts = false;
                    if(document.getElementById('scoreInput')) {
                        document.getElementById('scoreInput').focus();
                    }
                    this.game.participations.forEach(participant => { // Looking for next participant in line
                        if( next && ! done ) {
                            this.game.currentplayer = participant.player_id;
                            done = true;
                        }
                        if (participant.player_id == lookfor) {
                            next = true;
                        }
                    });
                    if((!next && !done) || (next && !done)) { // None found, so start over with first player.
                        this.game.currentplayer = this.game.participations[0].player_id;
                    }
                    if(mode == 'legwon' || mode == 'setwon') { // If not only next player, set next leg etc.
                        this.game.leg_starter = this.game.currentplayer;
                        this.game.set_starter = this.game.currentplayer;
                    }
                    this.speak("Next player");
                    this.storeGame(this.game);
                }
            },            
            noDarts: function (game) {
                var darts = 0;
                game.participations.forEach(participant => {
                    darts += participant.orddarts && participant.orddarts.length;
                }
                );
                return darts == 0;
            },
            noOpponent: function (game) {
                return game.participations.length == 1;
            },
            isPending: function (game) {
                return game.participations.length > 1 && game.status == 'on' && this.noDarts(game);
            },
            isOn: function (game) {
                return game.status == 'on' && game.participations.length > 1 && !this.noDarts(game);
            },
            isFinished: function (game) {
                return game.status == 'finished' || game.status == 'aborted';
            },
            myParticipation: function (game) {
                return game.participations.find(participant => participant.player_id == this.user.player_id);
            },
            createGame: function () {
                this.waitforapi = true;
                axios.post("/games", {
                        user: this.user,
                        game: this.newGame
                    })
                    .then((response) => {
                        this.getUserGames();
                        this.setPage('dashboard');
                        this.waitforapi = false;
                    })
                    .catch((error) => {
                        this.user.error = error.response.data.message;
                        this.waitforapi = false;
                    });
            },
            getAllGames: function () {
                this.waitforapi = true;
                axios.get("/games", this.user)
                    .then((response) => {
                        this.games = response.data.games;
                        this.waitforapi = false;
                    })
                    .catch((error) => {
                        this.user.error = error.response.data.message;
                        this.waitforapi = false;
                    }
                );
            },
            getUserGames: function () {
                this.waitforapi = true;
                axios.get("/usergames", this.user)
                    .then((response) => {
                        this.user.games = response.data.games;
                        this.getUserLeagues();
                        this.waitforapi = false;
                    })
                    .catch((error) => {
                        this.user.error = error.response.data.message;
                        this.waitforapi = false;
                    });
            },
            loadGame: function (game) {
                this.getGame(game);
            },
            getGame: function (game) {
                this.waitforapi = true;
                axios.get("/games/" + game.id, this.user)
                    .then((response) => {
                        this.waitforapi = false;
                        if( this.firstLoad = this.game.id != response.data.game.id ) {
                            this.secret = response.data.secret;
                        }
                        this.game = response.data.game;
                        this.currentplayer = response.data.game.currentplayer;
                        if(!this.game.leg_starter) {
                            this.game.leg_starter = this.game.currentplayer;
                        }
                        if(!this.game.set_starter) {
                            this.game.set_starter = this.game.currentplayer;
                        }
                        this.game.participations.forEach(participant => {
                            if(this.displayScore[participant.id] == undefined) {
                                this.displayScoreCntDown[participant.id] = Math.max(1,Math.floor(participant.score/100));
                                this.displayScore[participant.id] = participant.score;
                            }
                            participant.average = participant.average.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                        });
                        if(this.game.status == 'finished') {
                            this.getUser();
                        }
                        this.setPage('game');
                        if(this.firstLoad) {
                            console.log("%cFirst load "+this.game.id,"padding: 0.3em; color: white; background: #58a94e");
                            if(!this.justWatching) {
                                console.log("%cBinding to updategame"+this.game.id+"_"+this.getOpponent,"padding: 0.3em; color: white; background: #58a94e");
                                this.pusherChannel.bind('updategame'+this.game.id+"_"+this.getOpponent, function() {
                                    this.receiveUpdateGame++;
                                    this.updateGame();
                                }.bind(this));
                                console.log("%cBinding to opponentjoinedgame"+this.game.id+"_"+this.getOpponent,"padding: 0.3em; color: white; background: #58a94e")
                                this.pusherChannel.bind('opponentleftgame'+this.game.id+"_"+this.getOpponent, function( message ) {
                                    this.opponentLeftTheGame(message );
                                }.bind(this));
                                this.pusherChannel.bind('opponentjoinedgame'+this.game.id+"_"+this.getOpponent, function( message ) {
                                    this.opponentJoinedTheGame( message );
                                }.bind(this));
                                console.log("%cPushing join game "+this.game.id,"padding: 0.3em; color: white; background: #58a94e");
                                this.joinGame();
                                this.waitForOpponent();
                            } else {
                                console.log("%cJust Watching …","padding: 0.3em; color: white; background: #58a94e")
                                this.game.participations.forEach(participant => {
                                    if(participant.player_id != this.user.player_id) {
                                        console.log("%cBinding to updategame"+this.game.id+"_"+participant.player_id,"padding: 0.3em; color: white; background: #58a94e");
                                        this.pusherChannel.bind('updategame'+this.game.id+"_"+participant.player_id, function( message ) {
                                            this.receiveUpdateGame++;
                                            this.updateGame( message );
                                        }.bind(this));
                                        this.pusherChannel.bind('opponentjoinedgame'+this.game.id+"_"+participant.player_id, function( message ) {
                                            this.opponentJoinedTheGame( message );
                                        }.bind(this));
                                    }
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.waitforapi = false;
                    });
            },
            joinGame: function () {
                if(!this.game) {
                    return;
                }
                console.log("%cJoining game "+this.game.id+"_"+this.user.player_id,"padding: 0.3em; color: white; background: #58a94e");
                axios.post("/opponentjoinedgame", {
                        eventId: this.game.id+"_"+this.user.player_id,
                        gameId: this.game.id,
                        player_id: this.user.player_id
                    }).then((response) => {
                        this.updateGame();
                    }).catch((error) => {
                        console.error("Error joining game", error);
                    }
                )
            },
            leaveGame: function () {
                if(!this.game) {
                    return;
                }
                console.log("%Leaving game "+this.game.id,"padding: 0.3em; color: white; background: #58a94e");
                axios.post("/leavegame", {
                        eventId: this.game.id+"_"+this.user.player_id,
                    }).then((response) => {
                        this.pusherChannel.unbind('opponentleftgame'+this.game.id+"_"+this.getOpponent);
                    }).catch((error) => {
                        console.error("Error leaving game", error);
                    }
                )
            },
            waitForOpponent: function ( id ) {
                if(!this.justWatching) {
                    if(this.opponentJoinedGame[this.getOpponent]) {
                        return;
                    }
                } else {
                    let everyoneJoined = true;
                    this.game.participations.forEach(participant => {
                        if(participant.player_id != this.user.player_id) {
                            if(!this.opponentJoinedGame[participant.player_id]) {
                                return everyoneJoined = false;
                            }
                        }
                    });
                    if(everyoneJoined) {
                        return;
                    }
                }
                window.setTimeout(function() {
                    this.waitForOpponent();
                }.bind(this), 2000);
            },
            opponentJoinedTheGame: function ( message ) {
                console.log("%cOpponent joined game "+this.game.id,"padding: 0.3em; color: black; background: #ffff00");
                this.pushUpdateWebcam();
                if(this.opponentJoinedGame[ message. player_id] ) {
                    console.error("Opponent already joined game");
                    return;
                }
                this.opponentJoinedGame[message.player_id] = true;
                this.joinGame();
            },
            opponentLeftTheGame: function ( message ) {
                console.log("%cOpponent left game "+this.game.id,"padding: 0.3em; color: white; background: #58a94e");
                this.opponentJoinedGame[message.player_id] = false;
                var webcamGuest = document.querySelector("#webcam1Guest");
                if (webcamGuest) {
                    webcamGuest.srcObject = null;
                }
            },
            updateGame: function () {
                this.cntReceivedUpdateGame++;
                this.pushUpdateWebcam();
                this.getGame(this.game);
            },
            storeGame: function (game,nextPage) {
                this.waitforapi = true;
                axios.post("/games", {
                        game: this.game,
                        id: this.game.id,
                        currentplayer: this.game.currentplayer,
                        round: this.getRoundOfGame(),
                        leg: this.game.leg,
                        set: this.game.set,
                        leg_starter: this.game.leg_starter,
                        set_starter: this.game.set_starter,
                        dart: this.game.dart,
                        player: this.user.player_id,
                    })
                    .then((response) => {
                        this.waitforapi = false;
                        this.getUser();
                        this.getPlayers();                                
                        if(nextPage) {
                            window.setTimeout(() => {
                                this.setPage(nextPage);
                            }, 500);
                        }
                    })
                    .catch((error) => {
                        this.user.error = error.response.data.message;
                        this.waitforapi = false;
                    });
            },
            storeParticipant: function (participation,whatsNext) {
                this.waitforapi = true;
                axios.post("/participations", {
                        game: this.game,
                        participation: participation,
                        round: this.getRoundOfGame(participation),
                        leg: this.game.leg,
                        set: this.game.set,
                        dart: this.game.dart,
                        legsWon: this.getWonLegs(participation),
                        setsWon: this.getWonSets(participation),
                        orddarts: participation.orddarts
                    })
                    .then((response) => {
                        this.waitforapi = false;
                        participation.orddarts = response.data.orddarts;
                        participation.stats = response.data.participation.stats;
                        participation.average = response.data.participation.average.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                        if(whatsNext == 'legWon') {
                            this.legWon(participation);
                        }
                    })
                    .catch((error) => {
                        this.user.error = error.response.data.message;
                        this.waitforapi = false;
                    });
            },
            recalculateParticipationStats: function (participation) {
                this.waitforapi = true;
                axios.post("/recalculateparticipationstats", {
                        participation_id: participation.id
                    })
                    .then((response) => {
                        this.waitforapi = false;
                        console.log(response.data);
                        participation.stats = response.data.stats;
                    })
                    .catch((error) => {
                        this.user.error = error.response.data.message;
                        this.waitforapi = false;
                    });
            },
            storeLeg: function (leg) {
                this.waitforapi = true;
                axios.post("/legs", {
                    leg: this.game.sets[this.game.set-1].legs[this.game.leg-1],
                    set: this.game.sets[this.game.set-1]
                })
                .then((response) => {
                    this.waitforapi = false;
                })
                .catch((error) => {
                    this.user.error = error.response.data.message;
                    this.waitforapi = false;
                });
            },                    
            storeSet: function (leg) {
                this.waitforapi = true;
                axios.post("/sets", {
                    set: this.game.sets[this.game.set-1]
                })
                .then((response) => {
                    this.waitforapi = false;
                })
                .catch((error) => {
                    this.user.error = error.response.data.message;
                    this.waitforapi = false;
                });
            },   
            getGameType: function(game) {
                if(game.league_id) {
                    return 'League';
                } else if(game.tournament_id) {
                    return 'Tournament';
                }
                return 'Single';
            },                 
            // User
            clearUser: function () {
                this.user.id = 0;
                this.user.name = "";
                this.user.firstname = "";
                this.user.lastname = "";
                this.user.colour = "";
                this.user.country = "";
                this.user.language = "";
                this.user.email = "";
                this.user.password = "";
                this.user.password_confirmation = "";
                this.user.remember_me = false;
                this.user.token = "";
                this.user.error = "";
                this.user.games = [];
            },
            setUser: function (user) {
                this.user.id = user.id;
                this.user.name = user.name;
                this.user.email = user.email;
                this.user.password = "";
                this.user.password_confirmation = "";
                this.user.remember_me = false;
                this.user.token = user.token;
                this.user.error = "";
            },
            addParticipant: function (game,player) {
                this.waitforapi = true;
                game.participations.push({
                    game_id: game.id,
                    player_id: player,
                    score: game.startscore,
                    sets: 0,
                    legs: 0,
                    orddarts: [],
                    average: 0
                });
                axios.post("/participations", {
                    participation: {
                        game_id: game.id,
                        player_id: player
                    }
                })
                .then((response) => {
                    this.waitforapi = false;
                    this.getGame(game);
                })
                .catch((error) => {
                    this.user.error = error.response.data.message;
                    this.waitforapi = false;
                });
            },  
            getUser: function () {
                this.waitforapi = true;
                axios
                    .get("/player")
                    .then((response) => {
                        this.user = response.data;
                        this.getUserGames();
                        this.waitforapi = false;
                    })
                    .catch((error) => {
                        this.clearUser();
                        this.waitforapi = false;
                    });
            },
            getPlayerById: function (id) {
                if( id == 0) {
                    return {id:0,user: {id: 0, name:'Bye'}};
                }
                return this.players.find(player => player.id == id);
            },
            getPlayers: function () {
                this.waitforapi = true;
                axios.get("/players")
                    .then((response) => {
                        this.players = response.data.players;
                        this.waitforapi = false;
                    })
                    .catch((error) => {
                        this.user.error = error.response.data.message;
                        this.waitforapi = false;
                    });
            },
            updatePlayer: function () {
                this.waitforapi = true;
                axios
                    .post("/players", this.user)
                    .then((response) => {
                        this.$emit("setWaitforapi", false);
                        this.waitforapi = false;
                    })
                    .catch((error) => {
                        this.user.error = error.response.data.message;
                        this.waitforapi = false;
                    });
            },
            logout: function () {
                axios
                    .post("/logout", this.user)
                    .then((response) => {
                        this.clearUser();
                        this.$emit("setWaitforapi", false);
                    })
                    .catch((error) => {
                        this.user.error = error.response.data.message;
                        this.emit("setUser", false);
                        this.$emit("setWaitforapi", false);
                    });
            },
            uploadPhoto: function (event) {
                const file = event.target.files[0];
                const formData = new FormData();
                formData.append('photo', file);
                formData.append('user', this.user.user_id);
                axios.post('/uploadphoto', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.getUser();
                })
                .catch((error) => {
                    console.log(error);
                });
            },
            // Select players
            addSelectedPlayer: function (player) {
                if(this.selectedPlayers.indexOf(player) == -1) {
                    this.selectedPlayers.push(player);
                }
            },
            removeSelectedPlayer: function (player) {
                var idx = this.selectedPlayers.indexOf(player);
                if(idx != -1) {
                    this.selectedPlayers.splice(idx,1);
                }
            },
            // League
            makeNewLeague: function() {
                //this.league = { name: '', rounds: [] };
                this.selectedPlayers = [];
                this.setPage('newleague');
                return;
            },
            generateRoundRobin() {

            //this.league = { rounds: [] };

            let shuffledPlayers = [...this.selectedPlayers];

            if(shuffledPlayers.length % 2) {
                shuffledPlayers.push({id:0,user: {id: 0, name:'Bye'}});
            }

            for (let i = shuffledPlayers.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * i);
                const temp = shuffledPlayers[i];
                shuffledPlayers[i] = shuffledPlayers[j];
                shuffledPlayers[j] = temp;
            }

            const numRounds = shuffledPlayers.length - 1;
            const half = Math.floor(shuffledPlayers.length / 2);

            for (let round = 1; round <= numRounds; round++) {
                const games = [];

                for (let i = 0; i < half; i++) {
                    var swapPlayers = Math.random()>0.5;
                    games.push({
                        league_id: false,
                        game_id: false,
                        round: round,
                        home_player_id: swapPlayers?shuffledPlayers[i].id:shuffledPlayers[numRounds - i].id,
                        away_player_id: swapPlayers?shuffledPlayers[numRounds - i].id:shuffledPlayers[i].id,
                    });
                }

                this.league.rounds.push({
                    round,
                    games,
                });

                shuffledPlayers.splice(1, 0, shuffledPlayers.pop());
            
                }
            },
            wildcardGame: function( match ) {
                return match.home_player_id == 0 || match.away_player_id == 0;
            },
            getUserLeagues: function () {
                this.waitforapi = true;
                axios.get("/userleagues", this.user)
                    .then((response) => {
                        this.user.leagues = response.data.leagues;
                        this.waitforapi = false;
                    })
                    .catch((error) => {
                        this.user.error = error.response.data.message;
                        this.waitforapi = false;
                    });
            },
            createLeague: function() {
                this.waitforapi = true;
                axios.post("/leagues", {
                        host_id: this.user.user_id,
                        league: this.league,
                        name: this.newLeague.name,
                        sets_cnt: this.newLeague.sets_cnt,
                        legs_cnt: this.newLeague.legs_cnt,
                        startscore: this.newLeague.startscore,
                        inputmode: this.newLeague.inputmode
                    }).then((response) => {
                        this.getUserLeagues();
                        this.setPage('dashboard');
                        this.waitforapi = false;
                    }).catch((error) => {
                        this.user.error = error.response.data.message;
                        this.waitforapi = false;
                    });
            },
            getLeague: function(league) {
                this.waitforapi = true;
                axios.get("/leagues/" + league.id, this.user)
                    .then((response) => {
                        this.waitforapi = false;
                        this.league = response.data.league;
                        console.log(this.league);
                        this.setPage('league');
                    }
                ).catch((error) => {
                    this.user.error = error.response.data.message;
                    this.waitforapi = false;
                });  
            },
            isUserParticipant: function(match) {
                return match.home_player_id == this.user.player_id || match.away_player_id == this.user.player_id;
            },

        },
        computed: {
            // UI
            touchDevice: function() {
                return 'ontouchstart' in window;
            },
            showGame: function () {
                return this.page == 'game';
            },
            showDashboard: function () {
                return this.page == 'dashboard';
            },
            showGames: function () {
                return this.page == 'games';
            },
            showNewGame: function () {
                return this.page == 'newgame';
            },
            showAccount: function () {
                return this.page == 'account';
            },
            showLeagues: function () {
                return this.page == 'leagues';
            },
            showLeague: function () {
                return this.page == 'league';
            },
            showNewLeague: function () {
                return this.page == 'newleague';
            },
            dartsleft: function () {
                return 4 - this.game.dart;
            },
            justWatching: function() {
                let foundPlayer = false;
                this?.game?.participations.forEach(participant => {
                    if(participant.player_id == this.user.player_id) {
                        foundPlayer = true;
                    }
                });
                return !foundPlayer;
            },
            hasOpponents: function () {
                return this.game.participations.length > 1;
            },
            // Games
            noGame: function () {
                return !this.game.id;
            },
            firstDart: function () {
                var firstDart = true;
                this.game.participations.forEach(participant => {
                    if (participant.player_id == this.game.currentplayer) {
                        if(participant.orddarts.length) {
                            firstDart = false;
                        }
                    }
                });
                return firstDart;
            },
            noGames: function () {
                return !this.user.games || !this.user.games.length;
            },
            hasFinished: function () {
                return this.game.status == 'finished' || this.game.status == 'aborted';
            },
            getNotFinishedGames: function () {
                if(this.user.games) {
                    return this.user.games.filter(game => game.status != 'finished');
                } else {
                    return [];
                }
            },
            getOnGames: function () {
                if(this.user.games) {
                    if(this.justMyOwnGames==="1") {
                        return this.user.games.filter(game => game.status == 'on' && game.participations.length > 1);
                    } else {
                        return this.games.filter(game => game.status == 'on' && game.participations.length > 1);
                    }
                } else {
                    return [];
                }
            },
            getPendingGames: function () {
                if(this.user.games) {
                    if(this.justMyOwnGames==="1") {
                        return this.user.games.filter(game => game.status == 'pending');
                    } else {
                        return this.games.filter(game => game.status == 'pending');
                    }
                } else {
                    return [];
                }
            },
            getFinishedGames: function () {
                if(this.user.games) {
                    if(this.justMyOwnGames==="1") {
                        return this.user.games.filter(game => game.status == 'finished' || game.status == 'aborted');
                    } else {
                        return this.games.filter(game => game.status == 'finished' || game.status == 'aborted');
                    }
                } else {
                    return [];
                }
            },
            getOpponent: function () {
                var opponent = false;
                if(!this.game?.participations) {
                    return false;
                };
                this.game.participations.forEach(participant => {
                    if (participant.player_id && participant.player_id != this.user.player_id) {
                        opponent = participant.player_id;
                    }
                });
                return opponent;
            },
            getScoreOfCurrentPlayer: function () {
                var score = 171;
                this.game.participations.forEach(participant => {
                    if (participant.player_id == this.game.currentplayer) {
                        score = participant.score;
                    }
                });
                return score;
            },
            currentPlayerCanCheckOut: function () {
                var score = this.getScoreOfCurrentPlayer;
                return score <= 170 && score > 1;
            },
            illegalScoreInput: function () {
                if(this.currentPlayerCanCheckOut && (this.cntDarts===false || this.cntDoubleDarts===false )) {
                    return true;
                }
                if(this.godMode && this.scoreInput<=this.getScoreOfCurrentPlayer ) {
                    return false;
                }
                return this.scoreInput > 180 || this.scoreInput < 0 || this.scoreInput == '' || this.scoreInput>this.getScoreOfCurrentPlayer;
            },
            // Leagues
            noLeagues: function () {
                return !this.user.leagues;
            },
            // User
            noUser: function () {
                return this.user.id == 0;
            },
            gameIsPending: function() {
                return this.game.status == 'pending';
            },
            waitingForBullout: function() {
                return this.game.status == 'on' && this.game.participations.length > 1 && !this.game.currentplayer
            },
            gameIsOn: function () {
                return this.game.status == 'on' && this.game.participations.length > 1 && this.game.currentplayer;
            },
            isCurrentPlayer: function () {
                return this.game.currentplayer == this.user.player_id;
            },
            userIsParticipant: function () {
                return this.game.participations.find(participant => participant.player_id == this.user.player_id) !== false;
            },
            // Select players
            getSelectedPlayers: function () {
                return this.selectedPlayers;
            },
            getNotSelectedPlayers: function () {
                return this.players.filter(player => this.selectedPlayers.indexOf(player) == -1);
            },
            // Leagues
            leagueStandings: function() {
                var standings = [];
                if(!this.league.id) {
                    return standings;
                }
                // console.log(Object.entries(this.league.rounds));
                Object.entries(this.league.rounds).forEach(round => {
                    round[1].games.forEach(game => {
                        if(game.game_id) {
                            if(standings["p"+game.home_player_id] == undefined) {
                                standings["p"+game.home_player_id] = {
                                    position: 0,
                                    name: this.getPlayerById(game.home_player_id).user.name,
                                    currentplayer: game.home_player_id == this.user.player_id,
                                    points: 0,
                                    played: 0,
                                    won: 0,
                                    lost: 0,
                                    draw: 0
                                }
                            }
                            if (game.home_score== 2 || game.away_score == 2 || game.home_score == 1 || game.away_score == 1) {
                                standings["p"+game.home_player_id].played++;
                            }
                            standings["p"+game.home_player_id].points += game.home_score;
                            standings["p"+game.home_player_id].won += game.home_score==2;
                            standings["p"+game.home_player_id].lost += game.home_score==0;
                            standings["p"+game.home_player_id].draw += game.home_score==1;
                            if(standings["p"+game.away_player_id] == undefined) {
                                standings["p"+game.away_player_id] = {
                                    position: 0,
                                    name: this.getPlayerById(game.away_player_id).user.name,
                                    currentplayer: game.away_player_id == this.user.player_id,
                                    played: 0,
                                    points: 0,
                                    won: 0,
                                    lost: 0,
                                    draw: 0
                                }
                            }
                            if (game.home_score== 2 || game.away_score == 2 || game.home_score == 1 || game.away_score == 1) {
                                standings["p"+game.away_player_id].played++;
                            }
                            standings["p"+game.away_player_id].points += game.away_score;
                            standings["p"+game.away_player_id].won += game.away_score==2;
                            standings["p"+game.away_player_id].lost += game.away_score==0;
                            standings["p"+game.away_player_id].draw += game.away_score==1;
                        }
                    });
                });
                standings = Object.values(standings);
                standings.sort((a,b) => {
                    if(a.points > b.points) {
                        return -1;
                    } else if(a.points < b.points) {
                        return 1;
                    }
                });
                var lastPoints = 0;
                var position = 0;
                standings.forEach((standing,idx) => {
                    if(standing.points != lastPoints) {
                        position++;
                    }
                    standing.position = position;
                });
                return standings;
            }
        },
        mounted: function () {
            document.getElementById('app').classList.remove('hide');
            this.getUser();
            this.getPlayers();
            this.initPusher();
            this.getAllGames();
            this.tickerTimer = setInterval(() => {
                this.ticker();
            }, 10);
        }
    });

    app.component('login', Login);
    app.component('selectplayers', SelectPlayers);
    app.component('selectgame', SelectGame);

    app.mount('#app');

})
