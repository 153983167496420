<script setup>
const props = defineProps(["waitforapi", "user"]);
const emits = defineEmits(["user", "waitforapi"]);
</script>
<script>
const { default: axios } = require("axios");
export default {
    data: () => ({
        registerView: false,
        loginView: true,
        passwordResetView: false,
        waitForApi: false,
    }),
    methods: {
        showRegister: function () {
            this.loginView = true;
            this.registerView = false;
            this.passwordResetView = false;
        },
        showRegister: function () {
            this.registerView = true;
            this.loginView = false;
            this.passwordResetView = false;
        },
        showLogin: function () {
            this.registerView = false;
            this.loginView = true;
            this.passwordResetView = false;
        },
        showPasswordReset: function () {
            this.registerView = false;
            this.loginView = false;
            this.passwordResetView = true;
        },
        clearUser: function () {
            this.user.id = 0;
            this.user.name = "";
            this.user.email = "";
            this.user.password = "";
            this.user.password_confirmation = "";
            this.user.remember_me = false;
            this.user.token = "";
            this.user.error = "";
            this.$emit("setUser", this.user);
        },
        register: function () {
            this.$emit("setWaitforapi", true);
            axios
                .post("/register", this.user)
                .then((response) => {
                    this.$parent.getUser();
                    this.$emit("setWaitforapi", false);
                })
                .catch((error) => {
                    this.user.error = error.response.data.message;
                    this.$emit("setWaitforapi", false);
                });
        },
        login: function () {
            this.$emit("setWaitforapi", true);
            this.waitForApi = true;
            this.user.error = "";
            axios
                .post("/login", this.user)
                .then((response) => {
                    this.$parent.getUser();
                    this.waitForApi = false;
                    this.$emit("setWaitforapi", false);
                })
                .catch((error) => {
                    window.setTimeout(
                        function () {
                            this.waitForApi = false;
                            this.user.email = "";
                            this.user.password = "";
                            this.user.error = error.response.data.message;
                            this.emit("setUser", false);
                            this.$emit("setWaitforapi", false);
                        }.bind(this),
                        1000
                    );
                });
        },
        pwReset: function () {
            this.$emit("setWaitforapi", true);
            this.waitForApi = true;
            this.user.error = "";
            axios
                .post("/forgot-password", this.user)
                .then((response) => {
                    this.$parent.getUser();
                    this.waitForApi = false;
                    this.$emit("setWaitforapi", false);
                })
                .catch((error) => {
                    window.setTimeout(
                        function () {
                            this.waitForApi = false;
                            this.user.email = "";
                            this.user.password = "";
                            this.user.error = error.response.data.message;
                            this.emit("setUser", false);
                            this.$emit("setWaitforapi", false);
                        }.bind(this),
                        1000
                    );
                });
        },
    },
    computed: {
        noUser() {
            return !this.user.id;
        },
    },
};
</script>
<template>
    <div
        v-if="noUser && registerView"
        class="login fxc col"
        :class="{ disabled: waitForApi }"
    >
        <p class="thirsty">Game On!</p>
        <img src="/images/Dartmate-Type.svg" alt="" />
        <div class="form">
            <div class="formrow">
                <label for="name">Your username</label>
                <input type="text" name="name" id="name" v-model="user.name" />
            </div>
            <div class="formrow">
                <label for="email">Your email</label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    v-model="user.email"
                />
            </div>
            <div class="formrow">
                <label for="password">Your password</label>
                <input
                    type="password"
                    name="password"
                    id="password"
                    v-model="user.password"
                />
            </div>
            <div class="formrow">
                <label for="password_confirmation">Confirm password</label>
                <input
                    type="password"
                    name="password_confirmation"
                    id="password_confirmation"
                    v-model="user.password_confirmation"
                />
            </div>
            <p v-if="user.error" class="error txc">{{ user.error }}</p>
            <div class="formrow fxsb">
                <button
                    class="button"
                    @click="register"
                    :class="{ disabled: waitforapi }"
                >
                    Register
                </button>
                <span
                    class="link"
                    @click="showLogin"
                    :class="{ disabled: waitForApi }"
                    >Already a mate!? Login.</span
                >
            </div>
        </div>
    </div>
    <div
        v-if="noUser && loginView"
        class="login fxc col"
        :class="{ disabled: waitForApi }"
    >
        <p class="thirsty">Game On!</p>
        <img src="/images/Dartmate-Type.svg" alt="" />
        <div class="form">
            <div class="formrow">
                <label for="email">Your email</label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    v-model="user.email"
                />
            </div>
            <div class="formrow">
                <label for="password">Your password</label>
                <input
                    type="password"
                    name="password"
                    id="password"
                    v-model="user.password"
                />
            </div>
            <p>
                <span class="link" @click="showPasswordReset"
                    >Forgot password?</span
                >
            </p>
            <p v-if="user.error" class="error txc">{{ user.error }}</p>
            <div class="formrow fxsb">
                <button
                    class="button block"
                    @click="login"
                    :class="{ disabled: waitforapi }"
                >
                    Login
                </button>
            </div>
            <h3>Not yet registered!?<br>What this is.</h3>
            <p>Dartmate is a new community from and for dart players around the world. We are just starting and would be the happiest lads in the world, to have you on … board.</p>
            <p>Registering and playing 1:1 matches is free. We will add more features in the future, which will be available for a small fee. To be kept up to date, follow us on Instagram, Twitter or LinkedIn.</p>
            <p><span class="button secondary block" @click="showRegister">Register</span></p>
        </div>
    </div>
    <div
        v-if="noUser && passwordResetView"
        class="login fxc col"
        :class="{ disabled: waitForApi }"
    >
        <p class="thirsty">Game On!</p>
        <img src="/images/Dartmate-Type.svg" alt="" />
        <div class="form">
            <div class="formrow">
                <label for="email">Your email</label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    v-model="user.email"
                />
            </div>
            <p v-if="user.error" class="error txc">{{ user.error }}</p>
            <div class="formrow fxsb">
                <button
                    class="button"
                    @click="pwReset"
                    :class="{ disabled: waitforapi }"
                >
                    Request link
                </button>
                <span class="button secondary" @click="loginView = true"
                    >Login</span
                >
            </div>
        </div>
    </div>
</template>
